import React from 'react'
// import Helmet from 'react-helmet'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div id="main">
      <h1>Oops...</h1>
      <p>You are trying to reach a page that doesn't exist.</p>
      <a href="/">HOME</a>
    </div>
  </Layout>
)

export default NotFoundPage
